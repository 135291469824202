:root {
    --backgroundColor: #0e0f12;
    --buttonBlue: #0094ff;
    --secondaryBlack: #1a1d21;
    --accentGreen: #1fbf75;
    --textWhite: #ffffff;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    flex-direction: column;
    gap: 100px;
    background: var(--backgroundColor);
    overflow: hidden;
}

.top {
    min-height: 100vh;
}

main {
    background: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    gap: 100px;
    font-family: 'Space Grotesk', sans-serif;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: var(--buttonBlue);
}

::-webkit-scrollbar-track {
    background-color: var(--textWhite);
}

