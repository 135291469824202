

.aboutme {
    display: flex;
    flex-direction: column;
    gap: 50px;
    
}

.aboutmeTextContainer {
    position: relative;
    background-color: var(--secondaryBlack);
    display: flex;
    flex-direction: row;
    overflow: hidden;
    gap: 100px;
    height: 100%;
    padding: 5vw 10vw;
    align-items: center;
    justify-content: center;
}



.aboutmeText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    color: white;
    padding-right: 0px;
    width: 100%;

}


.showAboutMeImage {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;
}

@keyframes FromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}


.hideList {
    transform: translateX(-100%);
    opacity: 0;
    filter: blur(5px);
}

.showList {
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: all 500ms;
    transition-delay: 500ms;
}

.hideList2 {
    transform: translateX(-100%);
    opacity: 0;
    filter: blur(5px);
}

.showList2 {
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: all 500ms;
    transition-delay: 600ms;
}

.hideList3 {
    transform: translateX(-100%);
    opacity: 0;
    filter: blur(5px);
}

.showList3 {
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: all 500ms;
    transition-delay: 700ms;
}

.hideList4 {
    transform: translateX(-100%);
    opacity: 0;
    filter: blur(5px);
}

.showList4 {
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: all 500ms;
    transition-delay: 800ms;
}

.myImage {
    position: relative;
    width: 600px;
    max-width: 900px;
    margin-left: 150px;
    margin-top: 100px;
    opacity: 0;
}

.showMyImage {
    position: relative;
    width: 600px;
    max-width: 900px;
    opacity: 1;
    animation: FromRight 500ms linear;

}

.blob {
    position: absolute;
    width: 500px;
    top: -300px;
    right: -300px;

}

.blob2 {
    position: absolute;
    width: 500px;
    bottom: -300px;
    left: -200px;
    transform: rotate(90deg);
}

.pattern {
    position: absolute;
    width: auto;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    width: 100%;
}

.aboutmeTitle {
    font-family: 'Work Sans', sans-serif;
    position: relative;
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 3px;
    width: fit-content;
}

.aboutmeTitle:after {
    --deco-height: 0.3125em;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(var(--deco-height) * -0.625);
    height: var(--deco-height);
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' color='white' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='rgb(31,191,117)' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: auto 100%;
    background-repeat: round;
    background-position: 0em;
  }

  .list > li {
    font-size: 1.1em;
  }

li::before {
    content: "→";
    padding-right: 10px;
    color: var(--accentGreen);
    transform: translateX(-100%);
    left: 0px;
    position: absolute;
  }

  @media (max-width:1360px) {
    .aboutmeTextContainer {
        flex-direction: column;
        padding: 50px 30px;
    }

    .aboutmeText {
        width: 100%;
        align-items: center;
    }
  }

  @media (max-width:800px) {
    .showAboutMeImage {
        display: none;
    }
  }

