.wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 13%;
   margin-right: 13%;
   gap: 10vw;
    
}

.pageTitle {
    font-size: 2em;
    font-weight: 900;
    letter-spacing: .1em;

}

.title {
    color: var(--textWhite);
}

.secondaryTitle{
    margin-top: -30px;
    font-size: 1em;
}

.form {
    display: flex;
    gap: 30px;
    width: 50%;
}

.wrapper form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 18px;
}

.formText {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.formInputs {
    display: grid;
    width: 50%;
    grid-template-columns: 1fr;
}


.name {
    color: var(--textWhite);
    width: 300px;
    padding: 20px;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    background-color: var(--secondaryBlack);
}

.name::placeholder, .email::placeholder, .message::placeholder {
    color: var(--textWhite);
}

.name:focus, .email:focus, .message:focus {
    outline: none;
}

.email {
    color: var(--textWhite);
    margin-bottom: 20px;
    padding: 20px;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    background-color: var(--secondaryBlack);
}

.message {
    color: var(--textWhite);
    background-color: var(--secondaryBlack);
    margin-bottom: 20px;
    padding: 20px;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    font-size: 18px;
}

.submitText {
    margin-top: -35px;
    color: var(--buttonBlue);
    font-weight: 600;
}

.message::-webkit-scrollbar-track {
    background-color: var(--backgroundColor);
}

.message::-webkit-scrollbar-thumb {
    background-color: var(--textWhite);
}


.submit:hover {
    transition: ease-in-out 0.2s;
    color: var(--textWhite);
    background-color: rgb(192,192,192, 0.2);
}

.contactRight {
   display: flex;
   flex-wrap: nowrap;
   flex-direction: column;
   padding: 40px;
   border-radius: 15px;
   align-items: center;
   justify-items: center;
   row-gap: 20px;
   overflow: hidden;
}


.contactItems {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.animation {
    width: 5vw;
}

.contactRightText {
    grid-area: text;
    font-size: 2em;
    color: var(--textWhite);
}

.firstBox {
    grid-area: box1;
    background-color: var(--buttonBlue);
    width: 165px;
    height: 40px;
    border-radius: 15px 15px 0 15px;
}

.secondBox {
    background-color: var(--accentGreen);
    width: 300px;
    height: 40px;
    border-radius: 15px 15px 15px 0;
}

.thirdBox {
    background-color: var(--buttonBlue);
    width: 100px;
    height: 40px;
    border-radius: 15px 15px 0 15px;
}

.fourthBox {
    background-color: var(--accentGreen);
    width: 190px;
    height: 40px;
    border-radius: 15px 15px 15px 0;
}

@media (max-width:1350px) {

    .contactRight {
        display: none;
    }

    .form {
        width: 100%;
    }

    .wrapper {
        justify-content: center;
        align-items: flex-start;
    }


    .wrapper form {
        align-items: flex-start;
        justify-content: center;
    }
    
}

@media (max-width:840px) {
    .formText {
        display: grid;
    grid-template-columns: 1fr;
    }

}

