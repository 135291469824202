.stackTitle {
    font-size: 2em;
    color: white;
    letter-spacing: 0.15em;
    font-weight: 900;
}

.stackContainer {
    margin-left: 12%;
    margin-right: 12%;
}

.stackWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
}

.techContainer {
    display: grid;
    grid-template-columns: auto auto;
    place-content: center;
    place-items: center;
    border-radius: 15px;
    gap: 20px;
    background-color: var(--secondaryBlack);
    padding: 40px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}



.techImg {
    display: flex;
    width: 80px;
    min-width: 50px;
}


.techText {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
    color: var(--textWhite);
}

.techTitle {
    font-size: 1.5em;
    font-weight: 500;

}

.stackItems {
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 50px;
    width: 100%;
    grid-auto-flow: row;
    transition: all .8s;
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
}

.hidden {
    opacity: 0;
    transform: translateY(100%);
    filter: blur(25px);
}

@media (max-width:1000px) {
    .techContainer {
        grid-template-columns: auto;
    }
    
    .techDescription {
        display: none;
    }

}

@media (max-width:600px) {
    .stackItems {
        grid-template-columns: auto auto;
        grid-gap: 10px;
    }

    .techContainer {
        grid-template-columns: auto;
        width: 200px;
        background: none;
    }

    .techTitle {
        font-size: 1em;
    }
    
    .techDescription {
        display: none;
    }

    .stackWrapper {
        align-items: center;
    }

    .techImg {
        width: 30px;
    }

}